// import React from "react"
// import { navigate } from "gatsby"
// import Cookies from "universal-cookie"

// import Layout from "../components/layout"
// import Seo from "../components/seo"

// import Agegate from "../components/agegate"

// const AgeGate = () => {
//   let ageCookie = new Cookies()

//   if (ageCookie.get("isOldEnough")) {
//     navigate("/")
//   }

//   return (
//     <Layout>
//       <Seo title="Age Gate | Snickers Hunger Insurance" lang="en" />

//       <Agegate />
//     </Layout>
//   )
// }

// export default React.memo(AgeGate)

import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";
import Layout from "../../components/layoutAr";
import Seo from "../../components/seoAr";
import Agegate from "../../components/agegateAr";

const AgeGate = (props) => {
  console.log(props);
  useEffect(() => {
    if (props.path.startsWith("/")) {
      document.querySelector("body").classList.add("rightToLeft");
      document.querySelector("body").classList.add("arabic-fonts");
    }
  }, []);
  let ageCookie = new Cookies();

  if (ageCookie.get("isOldEnough")) {
    navigate("/");
  }

  return (
    <Layout>
      <Seo title="بوّابة تحديد العمر | تأمين سنكرس ضدّ الجوع" lang="ar" />
      <Agegate />
    </Layout>
  );
};

export default React.memo(AgeGate);
